import React from "react";
import Image from "gatsby-image";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";

const LogoWhite = () => {
  const logo = useStaticQuery(graphql`
    query LogoWhite {
      contentfulImage(slug: { eq: "logoTextWhite" }) {
        image {
          fluid (maxWidth: 300){
            ...GatsbyContentfulFluid_withWebp
          }
        }
        alt
      }
    }
  `);
  return (
    <>
      <Image
        fadeIn={false}
        loading="eager"
        fluid={logo.contentfulImage.image.fluid}
        alt={logo.contentfulImage.alt}
        css={css`
          @media (max-width: 425px) {
            max-width: 200px;
            height: auto;
          }
        `}
      />
    </>
  )
}

export default LogoWhite;