import React from "react";
import styled from "@emotion/styled";
import BackgroundImage from "gatsby-background-image";
import { css } from "@emotion/core";

import LogoWhite from "./logo-white";

const ImageBackground = styled(BackgroundImage)`
  background-position: ${(props) => (props.center ? "center" : "top 10% center")};
  width: 100%;
  background-size: cover;
  height: 55vh;
  position: relative;
  /* override the default margin for sibling elements  */
  + * {
    margin-top: 0;
  }
  @media only screen and (max-width: 825px) and (orientation: landscape) {
    min-height: 400px;
  }
`;

const WhiteToOrangeText = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: flex-start;
  padding: 25px calc((100vw - 700px) / 2) 2rem;
  width: 100%;
  margin-top: 0;
  h1 {
    font-family: "Reem Kufi";
    text-shadow: 1px 1px 3px #c2c2c266;
    font-size: 2.25rem;
  }
  p,
  a {
    color: #222;
    margin-top: 0;
  }
  a {
    margin-top: 0.5rem;
  }
`;

const OrangeToWhiteText = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100%;
  justify-content: flex-start;
  padding: 25px calc((100vw - 700px) / 2) 2rem;
  width: 100%;
  margin-top: 0;
  h1 {
    font-family: "Reem Kufi";
    text-shadow: 1px 1px 3px #c2c2c266;
    font-size: 2.25rem;
  }
  p,
  a {
    color: #222;
    margin-top: 0;
  }
  a {
    margin-top: 0.5rem;
  }
`;

const Hero = ({ fluid, alt, text, type, image, center }) => {
  return (
    <ImageBackground Tag="section" alt={alt} fluid={fluid} fadeIn={false} loading="eager" center={center}>
      {image === "header" && (
        <div
          css={css`
            position: absolute;
            top: 75px;
            width: 300px;
            margin-left: Calc(50% - 150px);
            margin-right: auto;
            @media (max-width: 425px) {
              top: 40px;
              width: 200px;
              margin-left: Calc(50% - 100px);
            }
          `}
        >
          <LogoWhite />
        </div>
      )}
      {type === "WtoO" && (
        <WhiteToOrangeText>{text && <h1>{text}</h1>}</WhiteToOrangeText>
      )}
      {type === "OtoW" && (
        <OrangeToWhiteText>{text && <h1>{text}</h1>}</OrangeToWhiteText>
      )}
    </ImageBackground>
  );
};

export default React.memo(Hero, (prev, next) => {
  return prev.alt === next.alt;
});
